const skillList = [
  {
    count: 80,
    experienceName: "REACT JS",
  },
  {
    count: 70,
    experienceName: "JAVASCRIPT",
  },
  {
    count: 60,
    experienceName: ".NET",
  },
  {
    count: 60,
    experienceName: "UI/UX",
  },
  {
    count: 50,
    experienceName: "DEVOPS",
  },
  {
    count: 100,
    experienceName: "WORDPRESS",
  },
];

export default skillList;
